import React from 'react';
import { Helmet } from 'react-helmet';
import { css } from '@emotion/react';
import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  ImgRight,
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
  TextJustify,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'
import config from '../website-config';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: ${colors.bodyBackground} //#fff;
  }

  // @media (prefers-color-scheme: dark) {
  //   .site-main {
  //     /* background: var(--darkmode); */
  //     background: ${colors.darkmode};
  //   }
  // }
`;

interface StrapiProps {
  location: Location;
  // pageContext: {
  //   currentPage: number;
  //   numPages: number;
  // };
  data: {
    strapiMaestro: {
      title: string
      seo: {
        excerpt: string
      }
      text: string
      image: {
        localFile:  {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
            original: {
              src: string
              height: number
              width: number
            }
          }
        }
      }
    };
  };
}

const Maestro: React.FC<StrapiProps> = props => (
  <IndexLayout>
    <Helmet>
      <html lang={config.lang} />
      <title>{props.data.strapiMaestro.title} - {config.title}</title>
      <meta name="description" content={props.data.strapiMaestro.seo.excerpt} />
      <meta property="og:site_name" content={config.title} />
      <meta property="og:title" content={`${props.data.strapiMaestro.title} - ${config.title}`} />
      <meta property="og:description" content={props.data.strapiMaestro.seo.excerpt} />
      <meta property="og:url" content={config.siteUrl + props.location.pathname} />
      <meta property="og:image" content={`${config.siteUrl}${props.data.strapiMaestro.image.localFile.childImageSharp.original.src}`}/>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${props.data.strapiMaestro.title} - ${config.title}`} />
      <meta name="twitter:description" content={props.data.strapiMaestro.seo.excerpt} />
      <meta name="twitter:url" content={config.siteUrl + props.location.pathname} />
      <meta name="twitter:image" content={`${config.siteUrl}${props.data.strapiMaestro.image.localFile.childImageSharp.original.src}`}/>
      {props.data.strapiMaestro.image.localFile.childImageSharp.original.width && <meta property="og:image:width" content={props.data.strapiMaestro.image.localFile.childImageSharp.original.width.toString()} />}
      {props.data.strapiMaestro.image.localFile.childImageSharp.original.height && <meta property="og:image:height" content={props.data.strapiMaestro.image.localFile.childImageSharp.original.height.toString()} />}
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">{props.data.strapiMaestro.title}</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                {/* <h5>
                  A starter template for Gatsby <br /> GitHub: <a href="https://github.com/scttcper/gatsby-casper">scttcper/gatsby-casper</a>
                </h5> */}
                <GatsbyImage image={getImage(props.data.strapiMaestro.image.localFile.childImageSharp.gatsbyImageData)!} css={ImgRight} alt={props.data.strapiMaestro.title}/>
                <ReactMarkdown css={TextJustify}>{props.data.strapiMaestro.text}</ReactMarkdown>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export const pageQuery = graphql`
  query maestroPageQuery {
    strapiMaestro {
      title
      seo {
        excerpt
      }
      text
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
            original {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;

export default Maestro;
